import React from "react";
import styles from "./Header.module.css";
import { useLocation } from "react-router-dom";

const Header = () => {
  const params = useLocation();

  const router = () => {
    if (params.pathname === "/contact") return "Contact Us";
    else if (params.pathname === "/about") return "About Us";
    else if (params.pathname === "/categories") return "Categories";
    else if (params.pathname === "/") return "New Arrivals";
  };

  return (
    <div className={styles.appBar}>
      <div className={styles.title}>{router()}</div>
      <div className={styles.divider} />
    </div>
  );
};

export default Header;
