import React from "react";
import { Container, Typography, Box } from "@mui/material";

const About = () => {
  return (
    <div>
      <Container component="main" maxWidth="md" style={{ marginBottom: "3rem" }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant="body1" align="justify" paragraph color="#777">
            Welcome to MidpointVape, your premier destination for wholesale vape distribution. Headquartered and
            Established in 2021 in Lebanon under MidPoint SARL, we embarked on a mission to revolutionize the vaping
            industry by becoming the first licensed distributor of vape products in our market.
          </Typography>
          <Typography variant="body1" align="justify" paragraph color="#777">
            Driven by our passion for excellence, we have since expanded our reach to West Africa, where we focus at the
            moment extensively to cater to the unique needs of this dynamic market. Bringing our exceptional range of
            vape products to new territories, we are dedicated to serving the African market with the same level of
            excellence and professionalism that defines our brand.
          </Typography>
          <Typography variant="body1" align="justify" paragraph color="#777">
            At MidpointVape, we specialize solely in distribution and being the first provider in the supply chain,
            catering to the diverse needs of wholesalers and retailers seeking premium vape products.
          </Typography>
          <Typography variant="body1" align="justify" paragraph color="#777">
            Furthermore, we are actively seeking to collaborate with new factories and suppliers who are looking to
            expand their products in new markets.
          </Typography>
          <Typography variant="body1" align="justify" paragraph color="#777">
            Whether you're an established factory looking to expand your product line, a distributor or a retailer
            seeking premium vape products, we invite you to join us on our journey. Together, let's shape the future of
            vaping.
          </Typography>
          <Typography variant="body1" align="justify" paragraph color="#777">
            Get in touch with us today to explore partnership opportunities and discover the possibilities with
            MidpointVape.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default About;
