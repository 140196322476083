import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import { CSSTransition } from "react-transition-group";
import styles from "./CustomeCarousel.module.css";

import Vape1 from "../../images/vape1.jpeg";
import Vape2 from "../../images/vape2.jpeg";
import Vape3 from "../../images/vape3.jpeg";
import Vape4 from "../../images/vape4.jpeg";
import Vape5 from "../../images/vape5.jpeg";
import Vape6 from "../../images/vape6.jpeg";

const images = [Vape1, Vape2, Vape3, Vape4, Vape5, Vape6];

const CustomCarousel = () => {
  const [index, setIndex] = useState(0);

  return (
    <Carousel
      autoPlay={true}
      interval={4000}
      animation="slide"
      duration={400}
      stopAutoPlayOnHover={false}
      indicators={images.length > 1}
      index={index}
      onChange={(index) => setIndex(index)}
      className={styles.carousel}
    >
      {images.map((image, i) => (
        <CSSTransition key={i} className={styles.image} timeout={500}>
          <img key={i} src={image} alt="s" />
        </CSSTransition>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
