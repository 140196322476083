import React from "react";
import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";
import styles from "./Brands.module.css";
import Brand1 from "../../images/brand1.jpeg";
import Brand2 from "../../images/brand2.jpeg";
import Brand3 from "../../images/brand3.jpeg";
import Brand4 from "../../images/brand4.jpeg";
import Brand5 from "../../images/brand5.jpeg";

const images = [Brand2, Brand1, Brand3, Brand4, Brand5];

const Brands = () => {
  return (
    <main className={styles.main}>
      <h2 className={styles.title}>Our Brands</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "2rem",
        }}
      >
        {images.map((image, index) => (
          <section key={index} className={`${styles.image} ${index === 0 ? styles.card : styles.card1}`}>
            <img src={image} alt="image" className={styles.image} />
          </section>
        ))}
      </div>
    </main>
  );
};

export default Brands;
