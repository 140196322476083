import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//components
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import TawkToWidget from "./components/Tawk";

//pages
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Header from "./components/Header/Header";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <TawkToWidget />
                <Navbar />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="about"
            element={
              <>
                <Navbar />
                <Header />
                <About />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="contact"
            element={
              <>
                <Navbar />
                <Header />
                <Contact />
                <Footer />
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
