import * as React from "react";

import "./Contact.css";

import { Box } from "@mui/material";

const Contact = () => {
  const [formData, setFormData] = React.useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("fullName", formData.fullName);
    form.append("email", formData.email);
    form.append("phoneNumber", formData.phoneNumber);
    form.append("message", formData.message);
    try {
      const response = await fetch("https://midpointvapeserverco.onrender.com/api/contact", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Form data sent successfully");
      } else {
        console.error("Failed to send form data");
      }
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };
  return (
    <div className="contact-container">
      <div className="r1">
        <div className="form-container">
          <form onSubmit={handleSubmit} className="contact-form">
            <label>Full Name</label>
            <input
              type="text"
              placeholder="Your Name"
              required
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />
            <label>Phone Number</label>
            <input
              type="number"
              placeholder="Enter Your Phone Number ..."
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            <label>Email Address</label>
            <input
              type="email"
              placeholder="Enter Your Email ..."
              required
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <label>Message</label>
            <textarea
              type="text"
              cols="30"
              rows="10"
              placeholder="Write a message here"
              required
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
            <button type="submit" className="send-button">
              Send
            </button>
          </form>
        </div>
        <div className="informations">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: {
                xs: "1rem",
                md: "1.5rem",
              },
            }}
            className="box"
          >
            <h4 style={{ marginBottom: "0.5rem", textAlign: "left" }} className="h4">
              Headquarter
            </h4>
            <div className="li">
              <i className="fa-solid fa-location-dot"></i>
              <p>Beirut, Lebanon</p>
            </div>
            <div className="li">
              <i className="fa fa-whatsapp"></i>
              <p>+961 3796269</p>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: {
                xs: "0.5rem",
                md: "1.5rem",
              },
            }}
            className="box"
          >
            <h4 style={{ marginBottom: "0.5rem", textAlign: "left" }}>Address 2</h4>
            <div className="li">
              <i className="fa-solid fa-location-dot"></i>
              <p>Nv Rte Bastos, Yaoundé, Cameroon</p>
            </div>
            <div className="li">
              <i className="fa-solid fa-phone"></i>
              <p>+237 90252523</p>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: {
                xs: "0.5rem",
                md: "1.5rem",
              },
            }}
            className="box"
          >
            <h4 style={{ marginBottom: "0.5rem", textAlign: "left" }} className="h4">
              Address 3
            </h4>
            <div className="li">
              <i className="fa-solid fa-location-dot"></i>
              <p>7 off atonsu road, Kumasi, Ghana</p>
            </div>
            <div className="li">
              <i className="fa-solid fa-phone"></i>
              <p>+233 247711331</p>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: {
                xs: "0.5rem",
                md: "1.5rem",
              },
            }}
            className="box"
          >
            <h4 style={{ marginBottom: "0.5rem", textAlign: "left" }} className="h4">
              Address 4
            </h4>
            <div className="li">
              <i className="fa-solid fa-location-dot"></i>
              <p>Rue de canal, zone 4, Abidjan, Côte d'Ivoire</p>
            </div>
            <div className="li">
              <i className="fa-solid fa-phone"></i>
              <p>+225 0706060689</p>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: {
                xs: "0.5rem",
                md: "1.5rem",
              },
            }}
            className="box"
          >
            <h4 style={{ marginBottom: "0.5rem", textAlign: "left" }}>Business Email</h4>
            <div className="li">
              <i className="fa-solid fa-envelope"></i>
              <p>info@midpointvape.co</p>
            </div>
          </Box>
        </div>
      </div>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5514.076013600667!2d35.48442409382604!3d33.858021540138886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f1756f85ea31d%3A0x583316fcf6a3427c!2sJnah!5e0!3m2!1sen!2slb!4v1714125982227!5m2!1sen!2slb"
          width="600"
          height="450"
          loading="lazy"
          // referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
