import React from "react";
import styles from "./Home.module.css";
import CustomCarousel from "../../components/Carousel/CustomeCarousel";
import Brands from "./Brands";

const Home = () => {
  return (
    <div className={styles.carousel}>
      <CustomCarousel />
      <Brands />
    </div>
  );
};

export default Home;
