import React, { useEffect } from "react";

const TawkToWidget = () => {
  useEffect(() => {
    const loadTawkToScript = () => {
      // Check if the screen width is larger than a certain threshold (e.g., 768 pixels)
      if (window.innerWidth > 200) {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://embed.tawk.to/6635974807f59932ab3bb778/1ht0ldspe";
        script.charset = "UTF-8";
        script.setAttribute("crossorigin", "*");
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      }
    };

    loadTawkToScript();

    return () => {};
  }, []);

  return <></>;
};

export default TawkToWidget;
