import React from "react";
import { Box, Typography, Link, Grid } from "@mui/material";
import styles from "./Footer.module.css";
import useWindowDimensions from "../../helper/useWindowDimensions";

const Footer = () => {
  const { width, height } = useWindowDimensions();
  return (
    <Box component="footer" className={styles.footer}>
      <Box className={styles.footerContainer}>
        <Grid container className={styles.row}>
          {width < 574 && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                marginBottom: { xs: "2rem", md: 0 },
              }}
              className={styles.footerCol}
            >
              <Typography variant="h4" component="h4" sx={{ color: "#fff", mb: 4.375 }}>
                Midpoint Vape
              </Typography>
              <ul>
                <li>
                  <Link href="/" sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}>
                    Brands
                  </Link>
                </li>
                <li>
                  <Link href="/about" sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link href="/contact" sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              marginBottom: { xs: "2rem", md: 0 },
            }}
            className={styles.footerCol}
          >
            <Typography variant="h4" component="h4" sx={{ color: "#fff", mb: 4.375 }}>
              Headquarter
            </Typography>
            <ul>
              <li>
                <i className={`${styles.icon} fa-solid fa-location-dot`}></i>
                <Link
                  href="https://maps.app.goo.gl/egNvPx5XBFbXa4gR6"
                  sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}
                  target="_blank"
                >
                  Beirut, Lebanon
                </Link>
              </li>
              <li>
                <i className={`${styles.icon} fa fa-whatsapp`}></i>
                <Link
                  href="https://wa.me/+9613796269"
                  sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}
                  target="_blank"
                >
                  +961 3796269
                </Link>
              </li>
              <li>
                <i className={`${styles.icon} fa-solid fa-envelope`}></i>
                <Link href="mailto:info@midpointvape.co" sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}>
                  info@midpointvape.co
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              marginBottom: { xs: "2rem", md: 0 },
            }}
            className={styles.footerCol}
          >
            <Typography variant="h4" component="h4" sx={{ color: "#fff", mb: 4.375 }}>
              Address 2
            </Typography>
            <ul>
              <li>
                <i className={`${styles.icon} fa-solid fa-location-dot`}></i>
                <Link
                  href="https://maps.app.goo.gl/XAb7KYGNbFDEBLa4A"
                  sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}
                  target="_blank"
                >
                  Nv Rte Bastos, Yaoundé, Cameroon
                </Link>
              </li>
              <li>
                <i className={`${styles.icon} fa-solid fa-phone`}></i>
                <Link href="#" sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}>
                  +237 90252523
                </Link>
              </li>
              <li>
                <i className={`${styles.icon} fa-solid fa-envelope`}></i>
                <Link href="mailto:info@midpointvape.co" sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}>
                  info@midpointvape.co
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              marginBottom: { xs: "2rem", md: 0 },
            }}
            className={styles.footerCol}
          >
            <Typography variant="h4" component="h4" sx={{ color: "#fff", mb: 4.375 }}>
              Address 3
            </Typography>
            <ul>
              <li>
                <i className={`${styles.icon} fa-solid fa-location-dot`}></i>
                <Link
                  href="https://maps.app.goo.gl/wuPTqKPhLggvhoRp9"
                  sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}
                  target="_blank"
                >
                  7 off atonsu road, Kumasi, Ghana
                </Link>
              </li>
              <li>
                <i className={`${styles.icon} fa-solid fa-phone`}></i>
                <Link href="#" sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}>
                  +233 247711331
                </Link>
              </li>
              <li>
                <i className={`${styles.icon} fa-solid fa-envelope`}></i>
                <Link href="mailto:info@midpointvape.co" sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}>
                  info@midpointvape.co
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              marginBottom: { xs: "2rem", md: 0 },
            }}
            className={styles.footerCol}
          >
            <Typography variant="h4" component="h4" sx={{ color: "#fff", mb: 4.375 }}>
              Address 4
            </Typography>
            <ul>
              <li>
                <i className={`${styles.icon} fa-solid fa-location-dot`}></i>
                <Link
                  href="https://maps.app.goo.gl/qP7GM95tbgAR8JT8A"
                  sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}
                  target="_blank"
                >
                  Rue de canal, zone 4, Abidjan, Côte d'Ivoire
                </Link>
              </li>
              <li>
                <i className={`${styles.icon} fa-solid fa-phone`}></i>
                <Link href="#" sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}>
                  +225 0706060689
                </Link>
              </li>
              <li>
                <i className={`${styles.icon} fa-solid fa-envelope`}></i>
                <Link href="mailto:vape.com" sx={{ color: "#bbb", "&:hover": { color: "#fff" } }}>
                  info@midpointvape.co
                </Link>
              </li>
            </ul>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3} className={styles.footerCol}>
            <Typography variant="h4" component="h4" sx={{ color: "#fff", mb: 4.375 }}>
              Follow us
            </Typography>
            <Box className={styles.socialLinks}>
              <Link
                href="https://www.facebook.com/cezarprojects/"
                sx={{ color: "#fff", "&:hover": { backgroundColor: "#fff", color: "#24262b" } }}
              >
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link
                href="https://instagram.com/cezar_projects"
                sx={{ color: "#fff", "&:hover": { backgroundColor: "#fff", color: "#24262b" } }}
              >
                <i className="fab fa-instagram"></i>
              </Link>
              <Link href="/" sx={{ color: "#fff", "&:hover": { backgroundColor: "#fff", color: "#24262b" } }}>
                <i className="fab fa-linkedin-in"></i>
              </Link>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
      <hr className={styles.hr} />
      <Box className={styles.subFooter}>
        <Typography sx={{ color: "#fff" }}>All Rights Reserved by &copy;MidpointVape 2024</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
